<template>
    <section class="content-container">
        <SectionTabs :tabs="tab_component"/>
        <router-view/>
    </section>
</template>

<script>
    import SectionTabs from "@/components/Section/SectionTabs.vue";
    export default {
        components: {
            SectionTabs,
        },
        data(){
            return {
                title: "Hotelería",
                tab_component: [{
                    name: "Dashboard",
                    route: "hotels-Dashboard",
                },
                {
                    name: "Estado por usuario",
                    route: "hotels-request",
                }],
            };
        },
       
    }

</script>